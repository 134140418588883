import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebookF, faLinkedinIn, faTiktok, faInstagram, faYoutube, faDiscord } from '@fortawesome/free-brands-svg-icons';

const SocialMediaIcons = () => {
  return (
    <div className="flex justify-center space-x-4 mt-8">
      <a href="https://twitter.com/GaimNetwork" target="_blank" rel="noopener noreferrer" className="text-gray-200 hover:text-primary">
        <FontAwesomeIcon icon={faTwitter} className="text-2xl" />
      </a>
      <a href="https://facebook.com/GaimNetwork" target="_blank" rel="noopener noreferrer" className="text-gray-200 hover:text-primary">
        <FontAwesomeIcon icon={faFacebookF} className="text-2xl" />
      </a>
      <a href="https://linkedin.com/in/GaimNetwork" target="_blank" rel="noopener noreferrer" className="text-gray-200 hover:text-primary">
        <FontAwesomeIcon icon={faLinkedinIn} className="text-2xl" />
      </a>
      <a href="https://tiktok.com/GaimNetwork" target="_blank" rel="noopener noreferrer" className="text-gray-200 hover:text-primary">
        <FontAwesomeIcon icon={faTiktok} className="text-2xl" />
      </a>
      <a href="https://instagram.com/GaimNetwork" target="_blank" rel="noopener noreferrer" className="text-gray-200 hover:text-primary">
        <FontAwesomeIcon icon={faInstagram} className="text-2xl" />
      </a>
      <a href="https://www.youtube.com/channel/GaimNetwork" target="_blank" rel="noopener noreferrer" className="text-gray-200 hover:text-primary">
        <FontAwesomeIcon icon={faYoutube} className="text-2xl" />
      </a>
      <a href="https://discord.gg/HF5drrAFDN" target="_blank" rel="noopener noreferrer" className="text-gray-200 hover:text-primary">
        <FontAwesomeIcon icon={faDiscord} className="text-2xl" />
      </a>
    </div>
  );
};

export default SocialMediaIcons;
