import React, { useEffect, useState } from 'react';
import { getBlogPosts } from '../services/api';
import BlogList from '../components/BlogList';
import Search from '../components/Search';
import CategoryFilter from '../components/CategoryFilter';
import Pagination from '../components/Pagination';
import Header from '../components/Header';
import Footer from '../components/Footer';

const Home = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(6);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await getBlogPosts();
        if (response.blogPosts && Array.isArray(response.blogPosts)) {
          setPosts(response.blogPosts); // Use the blogPosts array from the response
        } else {
          console.error('Expected an array of posts in response.blogPosts');
        }
      } catch (error) {
        console.error('Error setting posts:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  const filteredPosts = posts.filter(post =>
    post.title.toLowerCase().includes(searchQuery.toLowerCase()) &&
    (selectedCategory === '' || post.category === selectedCategory)
  );

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = filteredPosts.slice(indexOfFirstPost, indexOfLastPost);

  return (
    <div>
      <Header />
      <div className="container mx-auto p-4">
        <h1 className='flex flex-col items-center text-3xl uppercase text-audiowide mb-2'>
          GAIM.AI Blog
        </h1>
        <Search onSearch={setSearchQuery} />
        <CategoryFilter
          categories={[...new Set(posts.map(post => post.category))]}
          selectedCategory={selectedCategory}
          onSelectCategory={setSelectedCategory}
        />
        {loading ? <p>Loading...</p> : <BlogList posts={currentPosts} />}
        <Pagination
          currentPage={currentPage}
          totalPages={Math.ceil(filteredPosts.length / postsPerPage)}
          onPageChange={setCurrentPage}
        />
      </div>
      <Footer />
    </div>
  );
};

export default Home;
