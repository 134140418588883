import { faBackward } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';

const BlogPost = ({ post }) => {
  return (
    <article className="container mx-auto p-4 text-gray-200">
      <img src={post.headerImage} alt={post.title} className="w-full h-64 object-cover rounded mb-4" />
      <h1 className="text-4xl font-header text-white mb-4 uppercase text-center font-bold">{post.title}</h1>
      <div className="prose prose-lg prose-invert">
        <div dangerouslySetInnerHTML={{ __html: post.content }} />
      </div>
      <div className='w-full flex justify-center mt-10'>
        <Link to="/" className="mt-4 inline-block mx-auto px-4 py-2 rounded bg-primary text-dark"><FontAwesomeIcon icon={faBackward} className='mr-2' />Back to Blog Home</Link>
      </div>
    </article>
  );
};

export default BlogPost;
