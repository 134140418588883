import axios from 'axios';

const api = axios.create({
  baseURL: 'https://andy-back-end-loi4p.ondigitalocean.app',
  //baseURL: 'http://localhost:8080',
});

export const getBlogPosts = async () => {
  try {
    const response = await api.get('/blogposts/6647e961cc5bbe61ddc4c735');
    return response.data; // Return the actual data instead of the whole response
  } catch (error) {
    console.error('Error fetching blog posts:', error);
    throw error;
  }
};

export const getBlogPostById = async (id) => {
  try {
    const response = await api.get(`/blogposts/6647e961cc5bbe61ddc4c735/${id}`);
    return response.data; // Return the actual data instead of the whole response
  } catch (error) {
    console.error(`Error fetching blog post with id ${id}:`, error);
    throw error;
  }
};

export const getLeadMagnets = async () => {
  try {
    const response = await api.get('/leadmagnets');
    return response.data; // Return the actual data instead of the whole response
  } catch (error) {
    console.error('Error fetching leadmagnets:', error);
    throw error;
  }
};

export const getLeadMagnetById = async (id) => {
  try {
    const response = await api.get(`/leadmagnets/${id}`);
    return response.data; // Return the actual data instead of the whole response
  } catch (error) {
    console.error(`Error fetching leadmagnet with id ${id}:`, error);
    throw error;
  }
};

export const downloadLeadMagnet = async (id, email, groupId) => {
  try {
    const response = await api.post('/leadmagnets/download', {
      id,
      email,
      groupId
    });
    return response.data;
  } catch (error) {
    console.error('Error downloading lead magnet:', error);
    throw error;
  }
};