import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const FAQ = () => {
    return (
      <div>
        <Header />
        <div className="container mx-auto p-4">
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
            <h1 className="text-3xl font-header text-primary mb-6 text-center uppercase text-audiowide">Frequently Asked Questions</h1>
            <div className="mb-6">
              <h2 className="text-2xl font-bold mb-2">1. What is GAIM.AI?</h2>
              <p className="text-lg">GAIM.AI is an AI platform that provides easy-to-use tools for both beginners and experts to generate amazing, funny, and creative results. It includes various tools in one app with a familiar chat interface, available on web, iOS, and Android.</p>
            </div>
            <div className="mb-6">
              <h2 className="text-2xl font-bold mb-2">2. How can I get started with GAIM.AI?</h2>
              <p className="text-lg">You can start by signing up for a free account on our website or through the app. New users get 10,000 free chat/text credits and 25 AI image credits to try out our tools.</p>
            </div>
            <div className="mb-6">
              <h2 className="text-2xl font-bold mb-2">3. What features does GAIM.AI offer?</h2>
              <p className="text-lg">GAIM.AI offers a variety of AI tools including text generation, image creation, and more. All tools are designed to be user-friendly and powerful enough for advanced users.</p>
            </div>
            <div className="mb-6">
              <h2 className="text-2xl font-bold mb-2">4. How can I contact support?</h2>
              <p className="text-lg">For any support inquiries, you can reach us via email at <a href="mailto:support@gaimnetwork.com" className="text-primary">support@gaimnetwork.com</a> or join our Telegram group at <a href="https://t.me/GAIMJoin" className="text-primary">https://t.me/GAIMJoin</a>.</p>
            </div>
            <div className="mb-6">
              <h2 className="text-2xl font-bold mb-2">5. What platforms are supported by GAIM.AI?</h2>
              <p className="text-lg">GAIM.AI is available on web, iOS, and Android, ensuring you can access our tools from any device.</p>
            </div>
            <div className="mb-6">
              <h2 className="text-2xl font-bold mb-2">6. Can I use GAIM.AI for commercial purposes?</h2>
              <p className="text-lg">Yes, GAIM.AI can be used for commercial purposes. Our tools are designed to ensure that any content created is fully licensable for commercial use, but please review our terms of service for detailed information.</p>
            </div>

            <div className="mb-6">
                <h2 className="text-2xl font-bold mb-2">7. What types of AI models does GAIM.AI use?</h2>
                <p className="text-lg">GAIM.AI utilizes the latest in AI technology, including GPT-4 for natural language processing and DALL-E and Stable Diffusion for image generation. Our suite of tools is continuously updated to incorporate the latest advancements in AI.</p>
            </div>

            <div className="mb-6">
                <h2 className="text-2xl font-bold mb-2">8. How secure is GAIM.AI?</h2>
                <p className="text-lg">Security is a priority at GAIM.AI. We implement stringent data protections and comply with major data security standards to ensure that user data is treated with the highest level of security.</p>
            </div>

            <div className="mb-6">
                <h2 className="text-2xl font-bold mb-2">9. How does GAIM.AI handle user privacy?</h2>
                <p className="text-lg">At GAIM.AI, we respect user privacy. We do not share your data without your consent and operate in compliance with GDPR and other privacy laws. For more detailed information, please refer to our privacy policy.</p>
            </div>

            <div className="mb-6">
                <h2 className="text-2xl font-bold mb-2">10. Are there educational resources available to learn how to use GAIM.AI?</h2>
                <p className="text-lg">Yes, GAIM.AI offers comprehensive tutorials, webinars, and documentation to help users understand and utilize our AI tools effectively, catering to users of all skill levels.</p>
            </div>

            <div className="mb-6">
                <h2 className="text-2xl font-bold mb-2">11. Can GAIM.AI generate AI-based solutions for specific industries?</h2>
                <p className="text-lg">Absolutely! GAIM.AI is versatile and can be tailored to generate solutions for specific industries like marketing, finance, healthcare, and more, by creating customized content based on industry-specific requirements.</p>
            </div>

            <div className="mb-6">
                <h2 className="text-2xl font-bold mb-2">12. What are the limitations of free credits on GAIM.AI?</h2>
                <p className="text-lg">Free credits at GAIM.AI allow users to explore and utilize our AI tools. Once these credits are exhausted, users can opt to purchase additional credits or subscribe to our premium plans for continued access and more features.</p>
            </div>

            <div className="mb-6">
                <h2 className="text-2xl font-bold mb-2">13. How often does GAIM.AI update its AI tools?</h2>
                <p className="text-lg">We continuously improve and update our AI tools to ensure efficiency and incorporate the latest AI developments. Major updates are announced through our website and via newsletters.</p>
            </div>

            <div className="mb-6">
                <h2 className="text-2xl font-bold mb-2">14. Is there a community for GAIM.AI users to interact and share experiences?</h2>
                <p className="text-lg">Yes, GAIM.AI has a vibrant community on platforms like Discord and Telegram where users can interact, share experiences, and get help from other community members and our team.</p>
            </div>

            <div className="mb-6">
                <h2 className="text-2xl font-bold mb-2">15. How can I cancel my subscription?</h2>
                <p className="text-lg">You can cancel your subscription at any time. Simply go to the 'Account' section on the app or website and select 'Cancel Subscription.' Please note that the cancellation will take effect at the end of the current billing period.</p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  };
  
  export default FAQ;