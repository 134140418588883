import React from 'react';

const Footer = () => {
  return (
    <footer className='mt-10 border-t py-10'>
        <div id='appStoreLinks' className='flex flex-col gap-3 justify-center items-center'>
        <h1>DOWNLOAD GAIM.AI</h1>
        <p className='text-center' style={{padding: '0 50px', fontSize: '20px', fontFamily: 'Secular One'}}>GAIM.AI is available wherever and whenever you need it.</p>
            <a href='https://go.gaim.ai/ios' target="_blank"><img src="https://gaim.nyc3.cdn.digitaloceanspaces.com/AppStore2.png" alt="App Store" style={{ width: '250px' }} /></a>
            <a href='https://go.gaim.ai/android' target="_blank"><img src="https://gaim.nyc3.cdn.digitaloceanspaces.com/GooglePlay2.png" alt="Google Play Store" style={{ width: '250px' }} /></a>
            <a href='https://go.gaim.ai/gaimai' target="_blank"><img src="https://gaim.nyc3.cdn.digitaloceanspaces.com/MobileAndWeb2.png" alt="Mobile Web and Desktop" style={{ width: '250px' }} /></a>
            <p className='text-center' style={{padding: '0 50px', fontSize: '20px', fontFamily: 'Secular One', color: '#99abd6'}}>Get 10,000 FREE AI chat/text credits<br /> and 25 AI image credits just for signing up.</p>
            <a href='https://app.gaim.ai' target="_blank" rel="noreferrer"><img src="https://gaim.nyc3.cdn.digitaloceanspaces.com/logo100x100.png" alt="Logo" style={{ height: '100px', width: '100px' }} /></a>
        <p style={{padding: '10px 50px 0', fontSize: '20px', fontFamily: 'Roboto Condensed'}}>AI FOR EVERYONE.</p>
        <div style={{marginTop: '25px'}}>
            <a href='https://gaimnetwork.com/privacy-policy' target="_blank" rel="noreferrer" style={{color: '#fff'}}>Privacy Policy</a> | <a href='https://gaimnetwork.com/terms-and-conditions' target="_blank" rel="noreferrer" style={{color: '#fff'}}>Terms of Service</a>
        </div>
        </div>
        <p style={{textAlign: 'center', color: '#fff'}}>
        © {new Date().getFullYear()} GAIM Network LLC. All Rights Reserved.
        </p>
    </footer>
  );
};

export default Footer;
