import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import SocialMediaIcons from '../components/SocialMediaIcons';

const Contact = () => {
  return (
    <div>
      <Header />
      <div className="container mx-auto p-4">
        <div className="bg-gray-800 p-6 rounded-lg shadow-lg text-center">
          <img className="w-16 mb-2 mx-auto" src="https://gaim.nyc3.cdn.digitaloceanspaces.com/logo100x100.png" alt="Contact us" />
          <h1 className="text-3xl font-header text-primary mb-6">Contact Us</h1>
          <p className="text-lg mb-4">
            If you have any questions or need assistance, feel free to reach out to us via the following methods:
          </p>
          <div className="mb-4">
            <p className="text-lg">Email: <a href="mailto:support@gaimnetwork.com" className="text-primary">support@gaimnetwork.com</a></p>
          </div>
          <div className="mb-4">
            <p className="text-lg">Join our Telegram group: <a href="https://t.me/GAIMJoin" className="text-primary">https://t.me/GAIMJoin</a></p>
          </div>
          <div className="mb-4">
            <p className="text-lg">For more information, visit the GAIM Network Homepage: <a href="https://gaimnetwork.com" className="text-primary">https://gaimnetwork.com</a></p>
          </div>
          <SocialMediaIcons />
          
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
