import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import Home from './pages/Home';
import Blog from './pages/Blog';  // Import the new Blog component
import GetYourGuide from './pages/GetYourGuide';
import Post from './pages/Post';
import Contact from './pages/Contact';
import FAQ from './pages/FAQ';
import Pricing from './pages/Pricing';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="bg-dark text-white min-h-screen">
        
        <Routes>
          <Route path="/" element={<Home />} />  // Main landing page
          <Route path="/blog" element={<Blog />} />  // Blog home page
          <Route path="/get-your-guide/:id" element={<GetYourGuide />} />  // Lead magnet page
          <Route path="/post/:id" element={<Post />} />  // Individual blog post page
          <Route path="/contact" element={<Contact />} />  // Contact page
          <Route path="/faq" element={<FAQ />} />  // FAQ page
          <Route path="/pricing" element={<Pricing />} />  // Pricing page
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
