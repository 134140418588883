import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';

const Pricing = () => {
  const plans = ["FREE", "LITE", "BASIC", "PRO", "ENTERPRISE"];
  
  return (
    <div>
      <Header />
      <div className="container mx-auto p-4 pt-0">
        <div className="text-center mb-6">
          <h1 className="text-3xl font-header text-primary uppercase" style={{ color: '#6BF4EB' }}>GAIM.AI Plans and Packages</h1>
          <p className="text-lg mt-2 w-full lg:w-1/2 mx-auto">
            Start your AI adventure with GAIM.AI! Sign up now to receive 10,000 free AI credits and 25 AI image generation credits. 
            Upgrade anytime through the app to access our range of subscription plans tailored to elevate your creative potential.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {plans.map((plan, index) => (
            <div key={index} className={`plan bg-gray-800 p-6 rounded-lg shadow-lg text-center ${plan.toLowerCase()}`}>
              <h3 className="text-2xl font-bold mb-4" style={{ fontSize: '2rem' }}>{plan}</h3>
              {plan === "BASIC" && <div className="bg-primary text-dark py-1 px-2 rounded mb-4">Most Popular</div>}
              <ul className="mb-4">
                {plan === "FREE" && (
                  <>
                    <li className="text-gray-200"><FontAwesomeIcon icon={faCirclePlus} className="text-primary" /> 10,000 AI Chat/Text Credits</li>
                    <li className="text-gray-200"><FontAwesomeIcon icon={faCirclePlus} className="text-primary" /> Good Responses</li>
                    <li className="text-gray-200"><FontAwesomeIcon icon={faCirclePlus} className="text-primary" /> 25 AI Image Credits</li>
                    <li className="text-gray-200"><FontAwesomeIcon icon={faCirclePlus} className="text-primary" /> Access to All Tools</li>
                  </>
                )}
                {plan === "LITE" && (
                  <>
                    <li className="text-gray-200"><FontAwesomeIcon icon={faCirclePlus} className="text-primary" /> Good Responses</li>
                    <li className="text-gray-200"><FontAwesomeIcon icon={faCirclePlus} className="text-primary" /> AI Sight</li>
                    <li className="text-gray-200"><FontAwesomeIcon icon={faCirclePlus} className="text-primary" /> Unlimited Chat/Text</li>
                    <li className="text-gray-200"><FontAwesomeIcon icon={faCirclePlus} className="text-primary" /> 5 AI Image Credits</li>
                  </>
                )}
                {plan === "BASIC" && (
                  <>
                    <li className="text-gray-200"><FontAwesomeIcon icon={faCirclePlus} className="text-primary" /> Premium Responses</li>
                    <li className="text-gray-200"><FontAwesomeIcon icon={faCirclePlus} className="text-primary" /> 25 AI Image Credits</li>
                    <li className="text-gray-200"><FontAwesomeIcon icon={faCirclePlus} className="text-primary" /> Access to All Tools</li>
                    <li className="text-gray-200"><FontAwesomeIcon icon={faCirclePlus} className="text-primary" /> 120 AI Image Credits</li>
                  </>
                )}
                {plan === "PRO" && (
                  <>
                    <li className="text-gray-200"><FontAwesomeIcon icon={faCirclePlus} className="text-primary" /> Premium Responses</li>
                    <li className="text-gray-200"><FontAwesomeIcon icon={faCirclePlus} className="text-primary" /> AI Sight</li>
                    <li className="text-gray-200"><FontAwesomeIcon icon={faCirclePlus} className="text-primary" /> Unlimited Chat/Text</li>
                    <li className="text-gray-200"><FontAwesomeIcon icon={faCirclePlus} className="text-primary" /> 300 AI Image Credits</li>
                  </>
                )}
                {plan === "ENTERPRISE" && (
                  <>
                    <li className="text-gray-200"><FontAwesomeIcon icon={faCirclePlus} className="text-primary" /> Custom Image Packages</li>
                    <li className="text-gray-200"><FontAwesomeIcon icon={faCirclePlus} className="text-primary" /> Custom Business Solutions</li>
                    <li className="text-gray-200"><FontAwesomeIcon icon={faCirclePlus} className="text-primary" /> Let's Talk!</li>
                  </>
                )}
              </ul>
              {plan === "FREE" && (
                <div className="pricing-info">
                  <a href="https://app.gaim.ai" className="block bg-primary text-dark py-2 rounded-lg">
                    FREE - No Strings Attached
                  </a>
                  Start with your free account today!
                </div>
              )}
              {plan === "LITE" && (
                <div className="pricing-info">
                  <div className="text-gray-200">
                    $4.99/month
                  </div>
                  or
                  <div className="text-gray-200">
                    $53.89/year
                    <span className="block text-sm text-primary">Save 10%</span>
                  </div>
                </div>
              )}
              {plan === "BASIC" && (
                <div className="pricing-info">
                  <div className="text-gray-200">
                    $9.99/month
                  </div>
                  or
                  <div className="text-gray-200">
                    $107.89/year
                    <span className="block text-sm text-primary">Save 10%</span>
                  </div>
                </div>
              )}
              {plan === "PRO" && (
                <div className="pricing-info">
                  <div className="text-gray-200">
                    $19.99/month
                  </div>
                  or
                  <div className="text-gray-200">
                    $215.89/year
                    <span className="block text-sm text-primary">Save 10%</span>
                  </div>
                </div>
              )}
              {plan === "ENTERPRISE" && (
                <div className="text-gray-200">
                  <p>Contact our sales department for more information.</p>
                  <a href="mailto:sales@gaimnetwork.com?subject=GAIM%20AI%20Enterprise%20Inquiry" className="block mt-4 bg-dark text-white py-2 px-4 rounded-lg">
                    SALES@GAIMNETWORK.COM
                  </a>
                </div>
              )}
            </div>
          ))}
        </div>
        <Link
              to="https://app.gaim.ai"
              className="bg-primary text-dark text-center py-2 px-4 rounded-lg shadow-lg w-full sm:w-auto flex justify-center items-center text-xl mt-10"
            >
                <FontAwesomeIcon icon={faCirclePlus} className="mr-2" />
              SIGN UP FOR FREE
            </Link>
      </div>
      <Footer />
    </div>
  );
};

export default Pricing;
