import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getBlogPosts } from '../services/api';
import BlogList from '../components/BlogList';
import Search from '../components/Search';
import CategoryFilter from '../components/CategoryFilter';
import Pagination from '../components/Pagination';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { stripHtml } from '../utils/stripHtml';
import ImageCarousel from '../components/ImageCarousel';

const Home = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(6);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await getBlogPosts();
        if (response.blogPosts && Array.isArray(response.blogPosts)) {
          setPosts(response.blogPosts); // Use the blogPosts array from the response
        } else {
          console.error('Expected an array of posts in response.blogPosts');
        }
      } catch (error) {
        console.error('Error setting posts:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  const filteredPosts = posts.filter(post =>
    post.title.toLowerCase().includes(searchQuery.toLowerCase()) &&
    (selectedCategory === '' || post.category === selectedCategory)
  );

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = filteredPosts.slice(indexOfFirstPost, indexOfLastPost);

  const latestPosts = posts.slice(0, 2); // Get the latest 2 posts

  return (
    <div>
      <Header />
      <div className="container mx-auto p-4 pt-0">
        <div className="flex flex-col items-center">
        <div className='w-full lg:w-2/3 mx-auto pb-10 pt-5 lg:py-0'>
            <h1 className="font-header text-white mb-0 text-center uppercase w-[90%] mx-auto leading-none" style={{ fontSize: '7vw' }}>
                AI for Everyone.
            </h1>
        </div>
          <Link to="https://app.gaim.ai" className="w-full mb-4">
            <img
              src="https://gaim.nyc3.cdn.digitaloceanspaces.com/gaimAImobilehero2.jpg"
              alt="AI for everyone"
              className="hidden lg:block w-full lg:w-2/3 mx-auto rounded-lg shadow-lg"
            />
            <img
              src="https://gaim.nyc3.cdn.digitaloceanspaces.com/gaimAImobilehero.jpg"
              alt="AI for everyone"
              className="w-full lg:hidden mx-auto rounded-lg shadow-lg"
            />
          </Link>
          <div className="flex flex-col sm:flex-row items-center justify-center w-full mb-4">
            <Link
              to="https://app.gaim.ai"
              className="border border-primary text-primary text-center py-2 px-4 rounded-lg shadow-lg mb-2 sm:mb-0 sm:mr-2 w-full sm:w-auto"
            >
              GO TO THE APP
            </Link>
            <Link
              to="https://app.gaim.ai"
              className="bg-primary text-dark text-center py-2 px-4 rounded-lg shadow-lg w-full sm:w-auto"
            >
              SIGN UP FOR FREE
            </Link>
          </div>
          <div className="text-center mb-4">
            <p>and get 10,000 free chat/text credits and 25 AI image credits</p>
          </div>
          <div className="text-center mb-8">
            <h1 className="text-3xl font-header text-primary mb-6">THE MAGIC OF GAIM</h1>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div className="flex items-center">
                <div className="text-green-500 text-xl mr-2">✅</div>
                <div className="text-lg">EASY-TO-USE TOOLS</div>
              </div>
              <div className="flex items-center">
                <div className="text-green-500 text-xl mr-2">✅</div>
                <div className="text-lg">PERFECT FOR AI BEGINNERS</div>
              </div>
              <div className="flex items-center">
                <div className="text-green-500 text-xl mr-2">✅</div>
                <div className="text-lg">POWERFUL ENOUGH FOR EXPERTS</div>
              </div>
              <div className="flex items-center">
                <div className="text-green-500 text-xl mr-2">✅</div>
                <div className="text-lg">ALL TOOLS IN ONE APP</div>
              </div>
              <div className="flex items-center">
                <div className="text-green-500 text-xl mr-2">✅</div>
                <div className="text-lg">FAMILIAR CHAT INTERFACE</div>
              </div>
              <div className="flex items-center">
                <div className="text-green-500 text-xl mr-2">✅</div>
                <div className="text-lg">WEB, IOS, ANDROID</div>
              </div>
            </div>
            <p className="mt-6">
              With GAIM.AI, you can generate amazing, funny, creative results with just a few clicks. Try it out for free!
            </p>
            <Link
              to="https://app.gaim.ai"
              className="bg-primary text-dark text-center py-2 px-4 rounded-lg shadow-lg mt-6 inline-block"
            >
              GET STARTED
            </Link>
          </div>
        </div>

        {/* Image Carousel */}
        <div className='my-24'>
          <ImageCarousel />
        </div>

        {/* Blog Section */}
        <div className="mt-8">
          <h2 className="text-3xl font-header text-primary mb-6 text-center uppercase">Latest Blog Posts</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {latestPosts.map((post) => (
              <div key={post._id} className="bg-gray-800 p-6 rounded-lg shadow-lg">
                <img
                  src={post.headerImage}
                  alt={post.title}
                  className="w-full h-48 object-cover rounded mb-4"
                />
                <h3 className="text-2xl font-header mb-2 uppercase">{post.title}</h3>
                <p>{stripHtml(post.content).substring(0, 150)}...</p>
                <Link to={`/post/${post._id}`} className="text-primary mt-2 inline-block">Read more</Link>
              </div>
            ))}
          </div>
          <Link to="/blog" className="bg-primary text-dark text-center py-2 px-4 rounded-lg shadow-lg mt-6 flex justify-center items-center">
            View All Blog Posts
          </Link>
        </div>
        <div className='flex flex-col lg:flex-row justify-center items-center mt-20 gap-5'>
            <Link to="/faq" className="w-full lg:w-1/3 border border-primary text-primary text-center py-2 px-4 rounded-lg shadow-lg lg:mb-0">
                Frequently Asked Questions
            </Link>
            <Link to="/pricing" className="w-full lg:w-1/3 border border-primary text-primary text-center py-2 px-4 rounded-lg shadow-lg lg:mb-0">
                Pricing
            </Link>
            <Link to="/contact" className="w-full lg:w-1/3 border border-primary text-primary text-center py-2 px-4 rounded-lg shadow-lg">
                Contact Us
            </Link>
        </div>
        <Link to="https://app.gaim.ai" className="bg-primary text-dark text-center py-2 px-4 rounded-lg shadow-lg mt-6 flex justify-center items-center">
            Go to the GAIM.AI App
        </Link>

        <div className="container mx-auto p-4 mt-10"> 
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg"> 
          <h1 className="text-3xl font-header text-primary mb-6 text-center uppercase text-audiowide">Frequently Asked Questions</h1>

          <div className="mb-6">
              <h2 className="text-2xl font-bold mb-2">How do I create an image with AI?</h2>
              <p className="text-lg">Using GAIM.AI, you can create images by selecting one of our image generators such as the General Image Generator, Anime Image Generator, or Realistic Photograph Generator. Simply provide a text prompt, choose a style, and submit it to generate your desired image.</p>
          </div>

          <div className="mb-6">
              <h2 className="text-2xl font-bold mb-2">How do I chat with an AI chatbot?</h2>
              <p className="text-lg">To chat with an AI chatbot on GAIM.AI, select the desired chatbot such as the Business Bot, Developer Bot, or Marketing Bot. Type your questions or topics, and the chatbot will respond with helpful information and insights.</p>
          </div>

          <div className="mb-6">
              <h2 className="text-2xl font-bold mb-2">Can GAIM.AI help with content creation?</h2>
              <p className="text-lg">Yes, GAIM.AI offers tools like the Blog Post Writer, Email Writer, and Dialogue Writer to help you create high-quality content efficiently. Simply provide the necessary details and let the AI generate the content for you.</p>
          </div>

          <div className="mb-6">
              <h2 className="text-2xl font-bold mb-2">How can I generate social media content with AI?</h2>
              <p className="text-lg">GAIM.AI's Social Media Bot and Instagram Post Generator can help you create engaging social media content. Provide the details about your brand and the type of post you need, and the AI will generate posts, captions, and hashtags for you.</p>
          </div>

          <div className="mb-6">
              <h2 className="text-2xl font-bold mb-2">What are the subscription options for GAIM.AI?</h2>
              <p className="text-lg">GAIM.AI offers several subscription options including GAIM Basic (free with 10,000 credits), Lite, Basic, and Pro plans. You can also purchase additional image credits as needed. Visit our subscription page for more details.</p>
          </div>

          <div className="mb-6">
              <h2 className="text-2xl font-bold mb-2">Can I use GAIM.AI for commercial purposes?</h2>
              <p className="text-lg">Yes, GAIM.AI can be used for commercial purposes. Our tools are designed to ensure that any content created is fully licensable for commercial use, but please review our terms of service for detailed information.</p>
          </div>

          <div className="mb-6">
              <h2 className="text-2xl font-bold mb-2">What types of AI models does GAIM.AI use?</h2>
              <p className="text-lg">GAIM.AI utilizes the latest in AI technology, including GPT-4 for natural language processing and DALL-E and Stable Diffusion for image generation. Our suite of tools is continuously updated to incorporate the latest advancements in AI.</p>
          </div>

          <div className="mb-6">
              <h2 className="text-2xl font-bold mb-2">How do I get started with GAIM.AI?</h2>
              <p className="text-lg">Getting started with GAIM.AI is easy. Sign up on our website to receive 10,000 free AI credits and 25 free image generation credits. Download the app on iOS or Android, or use our web platform to begin exploring our AI tools.</p>
          </div>

          <div className="mb-6">
              <h2 className="text-2xl font-bold mb-2">What can I do with GAIM.AI's content generators?</h2>
              <p className="text-lg">GAIM.AI's content generators can help you create various types of content such as blog posts, emails, and marketing copy. Use tools like the Blog Post Ideas Generator and Email Onboarding Series to streamline your content creation process.</p>
          </div>

          <div className="mb-6">
              <h2 className="text-2xl font-bold mb-2">How does GAIM.AI help with marketing strategies?</h2>
              <p className="text-lg">GAIM.AI offers several tools to assist with marketing strategies, including the Marketing Analytics Plan, Pinterest Marketing Plan, and Facebook Ads Generator. These tools help you create comprehensive marketing plans and effective ads.</p>
          </div>
        </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
