import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

const ImageCarousel = () => {
  const images = [
    { src: 'https://andyonbase.nyc3.cdn.digitaloceanspaces.com/avatars/imagePromptMaster.jpg', header: "Chatbots", details: "Transform the way you interact with technology using GAIM.AI's Chatbots. From business assistance to creative brainstorming, our specialized bots are designed to streamline your workflow, enhance productivity, and provide intelligent responses tailored to your needs. Experience the future of conversational AI today!" },
    { src: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/AnimeGeneratorAI.jpg', header: "Image Generators", details: "Unleash your creativity with GAIM.AI's Image Generators. Whether you're looking to create stunning digital art, lifelike photos, or whimsical cartoons, our suite of generators turns your imagination into reality. Perfect for artists, designers, and anyone with a creative spark!" },
    { src: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/DentistAI.jpg', header: "Characters", details: "Meet your new AI companions with GAIM.AI's AI Characters. These lifelike personas can engage in meaningful conversations, provide expert advice, and even entertain with unique personalities. Discover a new level of interaction and learning with our diverse range of AI Characters. But, the fun doesn't stop there. Now you can chat with your favorite books, places, fictional characters, and much more." },
    { src: 'https://gaim.nyc3.cdn.digitaloceanspaces.com/AISightbyGAIMIcon2.jpg', header: "AI Sight", details: "See the world through the eyes of AI with GAIM.AI's AI Sight. This powerful computer vision feature can analyze and understand your photos, offering insights, identifications, and enhancements. Whether for fun or function, AI Sight adds a new dimension to your visual content." },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const intervalRef = useRef(null);

  useEffect(() => {
    if (!isHovered) {
      intervalRef.current = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, 4000);
    } else {
      clearInterval(intervalRef.current);
    }

    return () => clearInterval(intervalRef.current);
  }, [isHovered, images.length]);

  const handleImageClick = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div
      className="container mx-auto p-4 flex flex-col md:flex-row items-center justify-center"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="flex flex-col items-center space-y-4 md:w-1/4">
        <img
          src={images[0].src}
          alt="Placeholder 1"
          className={`cursor-pointer w-48 rounded-full border ${currentIndex === 0 ? 'border-4 pulse' : 'border-1'} border-primary`}
          onClick={() => handleImageClick(0)}
        />
        <img
          src={images[1].src}
          alt="Placeholder 2"
          className={`cursor-pointer w-48 rounded-full border ${currentIndex === 1 ? 'border-4 pulse' : 'border-1'} border-primary`}
          onClick={() => handleImageClick(1)}
        />
      </div>
      <div className="flex-1 md:w-1/2 p-4 text-center">
        <h1 className="text-3xl font-header text-primary mb-6 text-center uppercase text-audiowide">{images[currentIndex].header}</h1>
        <p>{images[currentIndex].details}</p>
        <Link
            to="https://app.gaim.ai"
            className="bg-primary text-dark text-center py-2 px-4 rounded-lg shadow-lg mt-6 inline-block"
        >
            TRY FOR FREE
        </Link>
      </div>
      <div className="flex flex-col items-center space-y-4 md:w-1/4">
        <img
          src={images[2].src}
          alt="Placeholder 3"
          className={`cursor-pointer w-48 rounded-full border ${currentIndex === 2 ? 'border-4 pulse' : 'border-1'} border-primary`}
          onClick={() => handleImageClick(2)}
        />
        <img
          src={images[3].src}
          alt="Placeholder 4"
          className={`cursor-pointer w-48 rounded-full border ${currentIndex === 3 ? 'border-4 pulse' : 'border-1'} border-primary`}
          onClick={() => handleImageClick(3)}
        />
      </div>
    </div>
  );
};

export default ImageCarousel;
