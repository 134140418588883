import React, { useState } from 'react';
import { downloadLeadMagnet } from '../services/api';

const LeadMagnetCard = ({ leadMagnet }) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [agree, setAgree] = useState(true);
  const groupId = '121609948156134441';

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!agree) {
      alert('You must agree to GAIM Network\'s privacy policy to continue.');
      return;
    }
    try {
      const response = await downloadLeadMagnet(leadMagnet._id, email, groupId);
      setMessage('Success! Check your email for your requested guide.');
    } catch (error) {
      setMessage('Failed to download lead magnet.');
    }
  };

  return (
    <div className="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl my-4">
      <div className="md:flex">
        <div className="md:shrink-0">
          <img className="h-48 w-full object-cover md:h-full md:w-48" src={leadMagnet.image_url} alt={leadMagnet.title} />
        </div>
        <div className="p-8">
          <div className="uppercase tracking-wide text-xl text-primary font-semibold">{leadMagnet.title}</div>
          <p className="block mt-1 text-lg leading-tight font-medium text-black">{leadMagnet.description}</p>
          {/*<p className="mt-2 text-gray-500">Created at: {new Date(leadMagnet.createdAt).toLocaleDateString()}</p>*/}
          {!message && (
            <form onSubmit={handleSubmit} className="mt-4">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email address</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="mt-1 block text-black w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder="you@example.com"
              />
              <button
                type="submit"
                className="mt-3 w-full inline-flex justify-center items-center py-2 pt-3 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-black bg-primary hover:bg-gray-900 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Get {leadMagnet.title}
              </button>
              <div className="mt-4">
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    checked={agree}
                    onChange={(e) => setAgree(e.target.checked)}
                    className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                  />
                  <span className="ml-2 text-sm text-gray-600">
                    I agree to GAIM Network's <a href="https://gaimnetwork.com/privacy-policy" target="_blank" rel="noopener noreferrer" className="text-indigo-600 underline hover:text-black">privacy policy</a>.
                  </span>
                </label>
              </div>
            </form>
          )}
          {message && <p className="mt-2 text-green-500">{message}</p>}
        </div>
      </div>
    </div>
  );
};

export default LeadMagnetCard;
