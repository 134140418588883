import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <header className="bg-dark pt-0">
      <div className="bg-primary text-center text-dark mb-2 py-1 text-sm md:text-base">
        <span>🚀 GAIM.AI 🚀 is available on the App Store and Google Play!</span>
      </div>
      <div className="p-4 pt-0 container mx-auto flex flex-col md:flex-row justify-between items-center">
        <Link to="/" className="flex items-center text-2xl font-header text-primary uppercase mb-0 md:mb-0">
          <img className="w-12 mr-2" src="https://gaim.nyc3.cdn.digitaloceanspaces.com/logo100x100.png" alt="GAIM.AI Homepage" />
          <div className="flex flex-col">
            GAIM.AI
            <span className="text-sm text-white">AI for Everyone</span>
          </div>
        </Link>
        <nav className="flex flex-wrap items-center justify-center md:justify-end mt-1">
          <Link to="/" className="text-primary mx-2 my-0 md:my-0 text-white">Home</Link>
          <Link to="/blog" className="text-primary mx-2 my-0 md:my-0 text-white">Blog</Link>
          <Link to="/faq" className="text-primary mx-2 my-0 md:my-0 text-white">FAQ</Link>
          <Link to="/pricing" className="text-primary mx-2 my-0 md:my-0 text-white">Pricing</Link>
          <Link to="/contact" className="text-primary mx-2 my-0 md:my-0 text-white">Contact</Link>
          <Link to="https://app.gaim.ai" className="mx-2 mt-3 md:mt-0 px-3 py-2 rounded bg-primary text-dark">Login to App</Link>
        </nav>
      </div>
    </header>
  );
};

export default Header;
