import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getLeadMagnetById } from '../services/api';
import { Link } from 'react-router-dom';
import LeadMagnetCard from '../components/LeadMagnetCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

const GetYourGuide = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [leadMagnet, setLeadMagnet] = useState(null);

  useEffect(() => {
    if (!id) {
        alert('No ID provided!', id)
      navigate('/');
      return;
    }

    const fetchLeadMagnet = async () => {
      try {
        const data = await getLeadMagnetById(id);
        if (!data) {
          navigate('/');
        } else {
          setLeadMagnet(data);
        }
      } catch (error) {
        console.error('Error fetching lead magnet:', error);
        navigate('/');
      }
    };

    fetchLeadMagnet();
  }, [id, navigate]);

  if (!leadMagnet) {
    return <p>Loading...</p>;
  }

  return (
    <div className="min-h-screen flex flex-col items-center justify-center">
      <div className="container mx-auto p-4 text-center">
        <h1 className="text-3xl font-header text-primary mb-6 text-center uppercase text-audiowide">Get Your Free Guide</h1>
        <p className="text-lg">You are seconds away from <span className='color-transition'>leveling<FontAwesomeIcon icon={faArrowUp} className="mx-2" />up</span> your game!</p>
      </div>
      <LeadMagnetCard leadMagnet={leadMagnet} />
      <div className="container mx-auto p-4 text-center">
        <p className="text-base">Simply provide your best email address above, and you will instantly receive your free guide directly to your inbox. It's like magic!</p>
        <p className="text-base mt-10">Powered by GAIM.AI</p>
        <div className="flex justify-center">
          <Link to="/" className="flex items-center text-2xl font-header text-primary uppercase mx-auto">
            <img className="w-12 mr-2" src="https://gaim.nyc3.cdn.digitaloceanspaces.com/logo100x100.png" alt="GAIM.AI Homepage" />
            <div className="flex flex-col items-start">
              GAIM.AI
              <span className="text-sm text-white">AI for Everyone</span>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default GetYourGuide;
